(function() {
  'use strict';

  angular.module('app')

  .constant('ReferralType', [
    "PEOPLE",
    "PLACE",
    "STRATEGY_CUSTOMER_SERVICES",
    "DEMOCRACY_LAW_TRANSFORMATION",
    "FINANCE_RESOURCES",
    "ENTRUST",
    "SCHOOLS",
    "PUBLIC_HEALTH",
    "ECONOMY",
    "HEALTH_CARE",
    "FAMILIES_COMMUNITIES",
    "STRATEGY_GOV_CHANGE",
    "STOKECC#CHILDREN_FAMILY_SERVICES",
    "STOKECC#CITY_DIRECTOR",
    "STOKECC#PLACE_GROWTH_PROSPERITY",
    "STOKECC#HOUSING_CUSTOMER_SERVICE",
    "STOKECC#PUBLIC_HEALTH_ADULT_SOCIAL_CARE",
    "STOKECC#UNITAS",
    "STOKECC#OTHER",
    "AP#PLANT_OPERATORS_BAYMAN",
    "AP#PLANT_SUPERVISOR",
    "AP#WAREHOUSE_FOREMAN",
    "AP#GENERAL_DUTIES_OPERATIVE",
    "AP#APEX",
    "AP#ENGINEER",
    "AP#TECHNICIAN",
    "AP#PACKAGE_GASES_DRIVER",
    "AP#BULK_DRIVER",
    "AP#CRYOEASE_DRIVER",
    "AP#SALES_CENTRE_DRIVER",
    "AP#ADMIN",
    "SCC#CORPORATE_SERVICES",
    "SCC#ECONOMY_INFRASTRUCTURE_AND_SKILLS",
    "SCC#HEALTH_AND_CARE",
    "SCC#SCHOOLS",
    "SCC#ENTRUST",
    "AISIN#ZONE_1_A",
    "AISIN#ZONE_1",
    "AISIN#ZONE_2",
    "AISIN#ZONE_3",
    "AISIN#ZONE_4",
    "AISIN#ZONE_5",
    "AISIN#ZONE_6",
    "AISIN#ZONE_7",
    "AISIN#ZONE_8",
    "AISIN#ZONE_9",
    "AISIN#ZONE_10_A",
    "AISIN#ZONE_10_B",
    "AISIN#ZONE_10_C",
    "AISIN#ZONE_10_D",
    "AISIN#ZONE_10_E",
    "AISIN#ZONE_10_F",
    "AISIN#ZONE_10_G",
    "SCC#CHILDREN_FAMILIES",
    "SCC#FINANCE",
    "AISIN#ZONE_10_H"
  ])



  .constant('AnsaJobTitle', [
    "ANSA#ABORTIST",
    "ANSA#BUILDING_SUPPORT_OFFICE",
    "ANSA#BUSINESS_CHANGE_OFFICER",
    "ANSA#BUSINESS_DEVELOPMENT_COORDINATOR",
    "ANSA#BUSINESS_DEVELOPMENT_OFFICER",
    "ANSA#BUSINESS_MANAGER_PARKS_AND_GROUNDS",
    "ANSA#MANAGING_DIRECTOR",
    "ANSA#WASTE_RECYCLING_LOADER",
    "ANSA#WASTE_RECYCLING_HGV_DRIVER_LOADER",
    "ANSA#WASTE_RECYCLING_TEAM_LEADER",
    "ANSA#SENIOR_TECHNICAL_OFFICER",
    "ANSA#TECHNICAL_OFFICER",
    "ANSA#WASTE_FLEET_OPERATIONS_MANAGER",
    "ANSA#LGV_SWEEPER_DRIVER",
    "ANSA#SENIOR_TEAM_LEADER_STREET_CLEANSING",
    "ANSA#WASTE_RECYCLING_AND_TEAM_LEADER_PERFORMANCE",
    "ANSA#WASTE_RECYCLING_AND_TEAM_LEADER",
    "ANSA#OPERATIONS_MANAGER",
    "ANSA#WASTE_COLLECTION_AND_TRANSFER_MANAGER",
    "ANSA#TRASNFER_STATION_PLANT_OPERATOR",
    "ANSA#MODERN_APPRENTICE",
    "ANSA#BUSINESS_MANAGER_WASTE_AND_STREET_CLEANSING",
    "ANSA#TEAM_LEADER_BUSINESS_SUPPORT",
    "ANSA#PARKS_INSPECTION_AND_DELIVERY_TEAM_LEADER",
    "ANSA#SENIOR_TEAM_LEADER_GROUNDS",
    "ANSA#GROUNDS_OPERATIVE",
    "ANSA#GROUNDS_SUPERVISOR",
    "ANSA#STREET_CLEANSING_AND_GROUNDS_SUPERVISOR",
    "ANSA#MARKET_SUPERVISOR",
    "ANSA#MARKETS_TEAM_LEADER",
    "ANSA#MARKET_DRIVER_OPERATOR",
    "ANSA#MARKET_OPERATOR",
    "ANSA#ENVIRONMENTAL_SERVICES_ADMIN_ASSISTANT",
    "ANSA#PARKS_PLANNING_AND_DESIGN_CONSULTANT",
    "ANSA#PARKS_DEVELOPMENT_CONSULTANS",
    "ANSA#PARKS_AND_RECREATION_MANAGER",
    "ANSA#PUBLIC_CONVENIENCE_CLEANER",
    "ANSA#FLEET_AND_DRIVER_TRAINING_MANAGER",
    "ANSA#MECHANIC_FITTER",
    "ANSA#INTERNAL_MAINTENANCE_WORKSHOP_MANAGER",
    "ANSA#TRADES_ASSISTANT",
    "ANSA#WORKSHOP_TEAM_LEADER",
    "ANSA#SENIOR_TEAM_LEADER_BUSINESS_SUPPORT",
    "ANSA#TRAINER_AUDITOR",
    "ANSA#BUSINESS_SUPPORT_OFFICER",
    "ANSA#WASTE_CONTRACT_INSPECTOR",
    "ANSA#CONTRACT_AND_PROCUMENT_MANAGER",
    "ANSA#CONTRACT_AND_PROCUMENT_OFFICER",
    "ANSA#HR_BUSINESS_PARTNER",
    "ANSA#GROUP_HEAD_OF_HR_AND_OD",
    "ANSA#SWEEPER_DRIVER",
    "ANSA#STREET_CLEANSING_OPERATIVE",
    "ANSA#SCHOOL_CROSSING_PATROL",
    "ANSA#TRANSPORT_OPERATIONS_OFFICER",
    "ANSA#DRIVER_ATTENDANT",
    "ANSA#OPERATIONS_ASSISTANT",
    "ANSA#LSTF_PROJECT_OFFICER",
    "ANSA#TRANSPORT_MANAGER",
    "ANSA#ADMINISTRATIVE_ASSISTANT",
    "ANSA#FINANCE_OFFICER",
    "ANSA#MONITORING_OFFICER",
    "ANSA#PUBLICITY_AND_INFORMATION_OFFICER",
    "ANSA#CONTRACTS_ASSISTANT",
    "ANSA#SENIOR_ADMINISTRATOR",
    "ANSA#SHOPMOBILITY_ATTENDANT",
    "ANSA#ACCESSIBILITY_OFFICER",
    "ANSA#CONTRACTS_AND_PERFORMANCE_MANAGER",
    "ANSA#SPECIALISED_TRANSPORT_MANAGER",
    "ANSA#BUSINESS_AND_PROJECTS_MANAGER",
    "ANSA#CONTRACTS_AND_PROCUREMENT_ADMINISTRATOR",
    "ANSA#FINANCE_MANAGER",
    "ANSA#SPECIALISED_TRANSPORT_OFFICER",
    "AES#AES_GBM",
    "AES#AES_ROM",
    "AES#AES_TM",
    "AES#AES_BSM",
    "AES#AES_HSM",
    "AES#AES_HBP",
    "AES#AES_STL",
    "AES#AES_TL",
    "AES#AES_SWT",
    "AES#AES_FSO",
    "AES#AES_BSO",
    "AES#AES_STO",
    "AES#AES_CO",
    "AES#AES_EO",
    "AES#AES_TWO",
    "AES#AES_DL",
    "AES#AES_LOA",
    "AES#AES_CW",
    "AES#AES_SSO",
    "AES#AES_CG",
    "AES#AES_SSOS",
    "AES#AES_HWO",
    "AES#AES_SGM",
    "AES#AES_AGM",
    "AES#AES_WT",
    "ANSA#ANSA_APPREN",
    "ANSA#ANSA_ARBMAN",
    "ANSA#ANSA_BIN",
    "ANSA#ANSA_BUS",
    "ANSA#ANSA_BFO",
    "ANSA#ANSA_BMO",
    "ANSA#ANSA_BSM",
    "ANSA#ANSA_BTM",
    "ANSA#ANSA_CTC",
    "ANSA#ANSA_CLE",
    "ANSA#ANSA_CTM",
    "ANSA#ANSA_CM",
    "ANSA#ANSA_CO",
    "ANSA#ANSA_CEO",
    "ANSA#ANSA_CPM",
    "ANSA#ANSA_CPA",
    "ANSA#ANSA_CTR",
    "ANSA#ANSA_CSA",
    "ANSA#ANSA_DMD",
    "ANSA#ANSA_DHRCS",
    "ANSA#ANSA_DRI",
    "ANSA#ANSA_ENV",
    "ANSA#ANSA_FCM",
    "ANSA#ANSA_FWM",
    "ANSA#ANSA_GTA",
    "ANSA#ANSA_GDA",
    "ANSA#ANSA_HGD",
    "ANSA#ANSA_HRAD",
    "ANSA#ANSA_HRA",
    "ANSA#ANSA_HRSM",
    "ANSA#ANSA_LER",
    "ANSA#ANSA_LVMA",
    "ANSA#ANSA_LVMO",
    "ANSA#ANSA_MCL",
    "ANSA#ANSA_MM",
    "ANSA#ANSA_MPO",
    "ANSA#ANSA_NED",
    "ANSA#ANSA_OPAD",
    "ANSA#ANSA_OPSO",
    "ANSA#ANSA_PADM",
    "ANSA#ANSA_PAMD",
    "ANSA#ANSA_PARC",
    "ANSA#ANSA_PADA",
    "ANSA#ANSA_PAID",
    "ANSA#ANSA_PAAS",
    "ANSA#ANSA_PRCO",
    "ANSA#ANSA_PRDM",
    "ANSA#ANSA_REC",
    "ANSA#ANSA_SSSO",
    "ANSA#ANSA_STLW",
    "ANSA#ANSA_STTL",
    "ANSA#ANSA_STI",
    "ANSA#ANSA_STRTL",
    "ANSA#ANSA_SSO",
    "ANSA#ANSA_SSS",
    "ANSA#ANSA_SC",
    "ANSA#ANSA_SCO",
    "ANSA#ANSA_SGSC",
    "ANSA#ANSA_TLFL",
    "ANSA#ANSA_TLWR",
    "ANSA#ANSA_TSPO",
    "ANSA#ANSA_TRAD",
    "ANSA#ANSA_TRSM",
    "ANSA#ANSA_TRIN",
    "ANSA#ANSA_TRTL",
    "ANSA#ANSA_WTPO",
    "ANSA#ANSA_WTSM",
    "ANSA#ANSA_WTSP",
    "ANSA#ANSA_WELL",
    "ORBITAS#ORB_BER",
    "ORBITAS#ORB_OTL",
    "ORBITAS#ORB_CT",
    "ORBITAS#ORB_BA",
    "ORBITAS#ORB_BM",
    "ORBITAS#ORB_HP",
    "ORBITAS#ORB_AA",
    "UAT-CSIMEDIA#ABORTIST"
  ])

  .constant('AnsaWorkLocation', [
    "ANSA_PYMS_LANE_DEPOT",
    "ANSA_WESTFIELDS",
    "ANSA_COMMERCIAL_ROAD_DEPOT",
    "ANSA_WEST_PARK_DEPOT",
    "ANSA_MUNICIPAL_OFFICES",
    "ANSA_BRUNSWICK_WHARF",
    "ANSA_CREWE_INDOOR_MARKET",
    "ANSA_MACCLESFIELD_TOWN_HALL",
    "ANSA_MACCLESFIELD_OUTDOOR_MARKET",
    "ANSA_MACCLESFIELD_INDOOR_MARKET",
    "ANSA_CREWE_MUNICIPAL_BUILDINGS",
    "ANSA_QUEENS_PARK",
    "ANSA_PHOENIX_HOUSE",
    "ANSA_BROOKS_LANE_MIDDLEWICH",
    "ANSA_CLEDFORD_LANE",
    "ORBITAS#ORBITAS_CREWE",
    "ORBITAS#ORBITAS_MACCLESFIELD",
    "ORBITAS#ORBITAS_HANDY",
    "AP#ABERDEEN",
    "AP#API_DUBLIN",
    "AP#BARDON",
    "AP#BARGEDDIE",
    "AP#BARNSLEY",
    "AP#BASINGSTOKE",
    "AP#BASINGSTOKE_ETG",
    "AP#BELFAST_SC",
    "AP#BILLINGHAM",
    "AP#BLACKPOOL_SC",
    "AP#BOW_SC",
    "AP#CARDIFF",
    "AP#CARRINGTON",
    "AP#MILLENNIUM_GATE",
    "AP#DIDCOT",
    "AP#DUBLIN_DEPOT",
    "AP#DUNDEE_SC",
    "AP#EDMONTON",
    "AP#ELLESMERE_PORT",
    "AP#FAWLEY",
    "AP#EXETER_SC",
    "AP#GALWAY",
    "AP#GATESHEAD_SC",
    "AP#HAMILTON",
    "AP#HERSHAM",
    "AP#HOME_BASED",
    "AP#HULL",
    "AP#HULL_SC",
    "AP#INVERGORDON_SC",
    "AP#INTEL_LEIXLIP",
    "AP#LIMERICK",
    "AP#LLANWERN",
    "AP#MANCHESTER_SC",
    "AP#MARGAM",
    "AP#PETERBOROUGH_SC",
    "AP#MOTHERWELL",
    "AP#RAINHAM",
    "AP#REDCAR",
    "AP#SLOUGH",
    "AP#SOUTHALL",
    "AP#STALLINGBOROUGH",
    "AP#STANLOW",
    "AP#STOKE",
    "AP#MIDDLESBRO_EFW",
    "AP#THETFORD",
    "AP#TASANIO",
    "AP#WALKDEN",
    "AP#WEST_BROMWICH",
    "AP#WORCESTER",
    "AP#POLANENPARK_AMS",
    "AP#CREWE",
    "AP#NEWARK",
    "AES#AES_FOWLCHURCH_DEPOT",
    "AES#AES_WATERSWALLOWS_DEPOT",
    "AES#AES_SILVERLANDS_DEPOT",
    "AES#AES_SURREYSTREET_DEPOT"
  ])

  .constant('Gender', [
    "MALE",
    "FEMALE"
  ])

  .constant('ProposedManagement', [
    "HANDS_ON",
    "ADVISE_AND_EDUCATION_PHONE_CONSULTING"
  ])

  .constant('Diagnosis', [
    "CERVICAL_WHIPLASH",
    "CERVICAL_SPINAL",
    "MECHANICAL_THORACIC",
    "MECHANICAL_LUMBAR",
    "LUMBAR",
    "SHOULDER_IMPINGEMENT",
    "ROTATOR",
    "TENNIS",
    "OTHER_ELBOW",
    "CARPAL",
    "WRIST_LOW",
    "WRIST_HIGH",
    "KNEE_MEDIAL",
    "ACL",
    "ANKLE_FIRST",
    "ANKLE_THIRD",
    "PLANTAR",
    "PLEASE_STATE"
  ])

  .constant('ActionPlan', [
    "HOME_EXERCISE_PROGRAM",
    "THINK_WELL_SERVICE",
    "ADVICE_AND_EDUCATION"
  ])

  .constant('AssessmentTypes', [
    "STANDARD",
    "ADVANCED",
    "HEALTHSAFETY"
  ])

  .constant('TriageAssessmentFindings', [
    "MECHANICAL_JOINT_DYSFUNCTION",
    "SOFT_TISSUE_INJURY",
    "LOSS_OF_FUNCTION",
    "LOSS_OF_RANGE",
    "SWELLING",
    "DISK_IRRITATION",
    "NERVE_ROOT_IRRITATION"
  ])

  .constant('ExtraSessionStatus', [
    "PENDING",
    "ACCEPTED",
    "DENIED"
  ])

  .constant('SurveyStatus', [
    "NOT_SENT",
    "SENT"
  ])

  .constant('AttendStatus', [
    "DID_NOT_ATTEND",
    "UNABLE_TO_ATTEND",
    "ATTENDED",
    "PENDING"
  ])

  .constant('AppointmentType', [
    "PHONE",
    "INITIAL",
    "FOLLOW_UP",
    "DISCHARGE",
    "FOLLOW_UP_PHONE",
    "PHONE_DISCHARGE",
    "AP#PHONE",
    "AP#INITIAL",
    "AP#FOLLOW_UP",
    "AP#DISCHARGE",
    "AP#FOLLOW_UP_PHONE",
    "AP#PHONE_DISCHARGE",
    "AP#ASSESSMENT_ONLY",
    "AP#HANDS_ON_TREATMENT"
  ])

  .constant('ConditionType', [
    "WORK_INJURY",
    "WORK_AGGRAVATED",
    "NON_WORK_RELATED"
  ])

  .constant('ChronicityOfInjury', [
    "ACUTE",
    "SUBACUTE",
    "CHRONIC",
    "ACUTE_ON_CHRONIC"
  ])

  .constant('BodyRegionOfInjury', [
    "NECK",
    "SHOULDER",
    "ARM",
    "ELBOW",
    "FOREARM",
    "WRIST",
    "HAND",
    "FINGER",
    "UPPER_BACK",
    "MIDDLE_BACK",
    "LOWER_BACK",
    "HIP",
    "THIGH",
    "KNEE",
    "LEG",
    "ANKLE",
    "FOOT",
    "TOES"
  ])

  .constant('TypeOfReferral', [
    "OCCUPATIONAL_HEALTH_REFERRAL",
    "SELF_REFERRAL",
    "STOKECC#SELF_REFERRAL",
    "STOKECC#OCCUPATIONAL_HEALTH_REFERRAL"
  ])

  .constant('InitationPeriod', [
    "AT_WORK_IN_WORK_TIME",
    "DURING_SPECIFIC_WORK_TASKS",
    "DURING_ACTIVITIES_OUTSIDE_OF_WORK"
  ])

  .constant('InitationNotify', [
    "RECORDED_IN_SITE_FIRST_AID_BOOK",
    "NOTIFIED_YOUR_SUPERVISOR"
  ])

  .constant('NatureOfWork', [
    "MANUAL",
    //"OFFICE_BASED",
    "SEDENTARY",
    "SEMI_MANUAL"
  ])

  .constant('MechanismOfInjury', [
      "HOSE",
      "PULLING_LIFTING",
      "CHRONIC_CONDITION",
      "HOBBY_SPORT",
      "OTHER",
      "CYLINDER_HANDLING",
      "INSIDIOUS_ONSET",
      "RTA",
      "VALVES",
      "HOME_ACTIVITIES",
      "OTHER_MSK",
      "OFFICE_ERGO"
    ])

  .constant('Reports', [
    "KPI_BASIC_REPORT",
    "KPI_ADVANCED_REPORT",
    "EFFICIENCY_REPORT",
    "TYPE_OF_INJURY_REPORT",
    "CONDITION_TYPE_REPORT",
    "SAME_INJURY_WITH_PREVIOUS_REFERRAL_REPORT",
    "TYPE_OF_INJURY_PER_DEPARTMENT_REPORT",
    "HIGHEST_TYPE_OF_INJURY_PER_DEPARTMENT_REPORT",
    "REFERRALS_CONFIRMED_WITHIN_24_HOURS_REPORT",
    "HANDLING_REQUEST_REPORT",
    "CHRONICITY_REPORT",
    "TYPE_OF_WORK_REPORT",
    "GENERAL_REPORT"
  ])

  .constant('ClosedWithoutReachingMinimumAppointmentsReason', [
    "HAS_MADE_EXCELLENT_PROGRESS_AND_CAN_SELF_MANAGE",
    "DOES_NOT_WISH_TO_ATTEND",
    "NO_CONTACT",
    "CASE_ON_HOLD",
    "REQUIRES_MEDICAL_INTERVENTION"
  ])

  .constant('WorkStatus', [
     "IN_WORK",
     "OFF_WORK",
     "RETURNED_TO_WORK",
     "AP#RETURNED_TO_WORK",
     "AP#AT_WORK_NORMAL_DUTIES",
     "AP#AT_WORK_MODIFIED_DUTIES",
     "AP#OFF_WORK_SELF_CERTIFIED",
     "AP#OFF_WORK_WITH_DOCTORS_NOTE",
     "AP#OTHER"

  ]);

}());
